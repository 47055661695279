import React, {useState} from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import ContactSection from "../components/Contact";
import {PageContainer} from "./PageElements";

function ContactPage() {
    const [isOpen, SetIsOpen] = useState(false);

    const toggle = () => {
        SetIsOpen(!isOpen);
    };
    return (
        <PageContainer>
            <SideBar isOpen={isOpen} toggle={toggle}/>
            <NavBar toggle={toggle}/>
            <ContactSection/>
            <Footer/>
        </PageContainer>
    );
}

export default ContactPage;
