import React from "react";
import {useNavigate} from 'react-router-dom';

import {Button} from "../../ButtonElements";
import {
    AboutContainer,
    AboutRow,
    AboutWrapper,
    ButtonWrapper,
    Column1,
    Column2,
    Image,
    ImageWrapper,
    SubTitle,
    TextWrapper,
    TopLine,
} from "./AboutElements";

import {aboutSectionData} from "./Data";


function AboutSection() {
    const navigate = useNavigate();

    return (
        <AboutContainer id={aboutSectionData.id}>
            <AboutWrapper>
                <AboutRow imgStart={aboutSectionData.imgStart}>
                    <Column1>
                        <ImageWrapper>
                            <Image src={aboutSectionData.img} alt={aboutSectionData.alt}/>
                        </ImageWrapper>
                    </Column1>
                    <Column2>
                        <TextWrapper>
                            <TopLine>{aboutSectionData.topLine}</TopLine>
                            <SubTitle
                                lightText={aboutSectionData.lightText}>{aboutSectionData.description}</SubTitle>
                            <ButtonWrapper>
                                <Button
                                    to='about'
                                    smooth={true}
                                    duration={500}
                                    onClick={() => navigate("/token")}
                                    spy={true}
                                    extact='true'
                                    offset={-80}
                                    primary={aboutSectionData.primary ? 1 : 0}
                                    dark={aboutSectionData.dark ? 1 : 0}
                                >
                                    {aboutSectionData.buttonLabel}
                                </Button>
                            </ButtonWrapper>
                        </TextWrapper>
                    </Column2>
                </AboutRow>
            </AboutWrapper>
        </AboutContainer>

    );
}

export default AboutSection;
