export const aboutSectionData = {
    id: "about",
    lightText: false,
    lightTextDescription: true,
    topLine: "The Vision",
    description: "CapyAI Token is not just any ordinary cryptocurrency, it's a fun and engaging way to invest in the world of memes and crypto! With its AI-generated capybara mascot, who loves to relax in a suit while sipping wine and eating oranges, CapyAI Token is a breath of fresh air in the world of cryptocurrencies.\n" +
        "\n" +
        "But don't let the fun and playful nature of this token fool you - it's built on the same secure and transparent blockchain technology as other top cryptocurrencies, providing users with peace of mind and confidence in their investment.\n" +
        "\n" +
        "Investing in CapyAI Token is a chance to be part of a unique community of like-minded individuals who share a passion for humor and crypto. Plus, with the potential for high volatility and significant returns, investing in this token can be an exciting opportunity for those looking to diversify their portfolio.\n" +
        "\n" +
        "So why not take a chance on CapyAI Token? With its AI-generated capybara mascot, fun and engaging community, and potential for significant returns, it's an investment opportunity that's hard to resist!",
    buttonLabel: "Learn More",
    pathName: "/about-us",
    imgStart: false,
    img: require("../../../images/smallbusiness.svg").default,
    alt: "BTC",
    dark: true,
    primary: true,
    darkText: false,
};
