import React from "react";
import {useNavigate} from 'react-router-dom';

import {Button} from "../../ButtonElements";
import {
    ButtonWrapper,
    Column1,
    Column2,
    Image,
    ImageWrapper,
    SubTitle,
    TextWrapper,
    TopLine,
    VisionContainer,
    VisionRow,
    VisionWrapper,
} from "./VisionElements";
import {visionSectionData} from "./Data";


function VisionSection() {
    const navigate = useNavigate();

    return (
        <VisionContainer id={visionSectionData.id}>
            <VisionWrapper>
                <VisionRow imgStart={visionSectionData.imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{visionSectionData.topLine}</TopLine>
                            <SubTitle
                                lightText={visionSectionData.lightText}>{visionSectionData.description}</SubTitle>
                            <ButtonWrapper>
                                <Button
                                    to='vision'
                                    smooth={true}
                                    duration={500}
                                    onClick={() => navigate("/token")}
                                    spy={true}
                                    extact='true'
                                    offset={-80}
                                    primary={visionSectionData.primary ? 1 : 0}
                                    dark={visionSectionData.dark ? 1 : 0}
                                >
                                    {visionSectionData.buttonLabel}
                                </Button>
                            </ButtonWrapper>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImageWrapper>
                            <Image src={visionSectionData.img} alt={visionSectionData.alt}/>
                        </ImageWrapper>
                    </Column2>
                </VisionRow>
            </VisionWrapper>
        </VisionContainer>

    );
}

export default VisionSection;
