export const tokenSectionData = {
    id: "token",
    lightBackground: true,
    lightText: false,
    lightTextDescription: true,
    topLine: "Whitepaper",
    headline: "Reading the Whitepaper should be prioritized before investing in a project. That's why we dedicated time creating this document, which has been reviewed by regulators, third-party audit firms and advisors, that includes legal and industry experts.",
    description: "test",
    buttonLabel: "Learn More",
    pathName: "/about-us",
    imgStart: false,
    img: require("../../images/smallbusiness.svg").default,
    alt: "BTC",
    dark: true,
    primary: true,
    darkText: false,
};
