import React from "react";
import {animateScroll as scroll} from "react-scroll";
import {FaFacebook, FaInstagram, FaTelegram, FaTwitter, FaYoutube} from "react-icons/fa";
import {
    FooterContainer,
    FooterLinks,
    FooterLinksContainer,
    FooterLinksItems,
    FooterLinksTitle,
    FooterLinksWrapper,
    FooterWrapper,
    SocialIconLink,
    SocialIcons,
    SocialLogo,
    SocialMedia,
    SocialMediaWrapper,
    WebsiteRights,
} from "./FooterElements";

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinksItems>
                            <FooterLinksTitle to='/company'>Company</FooterLinksTitle>
                            <FooterLinks to='/company'>About Us</FooterLinks>
                            <FooterLinks to='/roadmap'>Roadmap</FooterLinks>
                        </FooterLinksItems>
                        <FooterLinksItems>
                            <FooterLinksTitle to='/tokenomics'>Token</FooterLinksTitle>
                            <FooterLinks to='/tokenomics'>Tokenomics</FooterLinks>
                            <FooterLinks to='/whitepaper'>Whitepaper</FooterLinks>
                        </FooterLinksItems>
                        <FooterLinksItems>
                            <FooterLinksTitle to ='/contact'>Help & Support</FooterLinksTitle>
                            <FooterLinks to='/contact'>Contact Us</FooterLinks>
                        </FooterLinksItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrapper>
                        <SocialLogo to='/' onClick={toggleHome}>
                            Atomic Capy AI
                        </SocialLogo>
                        <WebsiteRights>
                            Copyright © {new Date().getFullYear()} Atomic Rodents. All Rights Reserved.
                        </WebsiteRights>

                        <SocialIcons>
                            <SocialIconLink href='//www.facebook.com/smallblox/'
                                            target='_blank'
                                            aria-label='smallBlox Facebook'>
                                <FaFacebook/>
                            </SocialIconLink>
                            <SocialIconLink href=''
                                            target='_blank'
                                            aria-label='smallBlox Instagram'>
                                <FaInstagram/>
                            </SocialIconLink>
                            <SocialIconLink href='https://t.me/smallBlox/'
                                            target='_blank'
                                            aria-label='smallBlox Telegram'>
                                <FaTelegram/>
                            </SocialIconLink>
                            <SocialIconLink href='https://twitter.com/1smallBlox1/'
                                            target='_blank'
                                            aria-label='Twitter'>
                                <FaTwitter/>
                            </SocialIconLink>
                            <SocialIconLink href='/'
                                            target='_blank'
                                            aria-label='YouTube'>
                                <FaYoutube/>
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrapper>
                </SocialMedia>
            </FooterWrapper>
        </FooterContainer>
    );
};

export default Footer;
