import styled from 'styled-components';

export const PageContainer = styled.div`
  position: relative;
`;

export const Content = styled.div`
  /* your content styles here */
`;

export const FooterWrapper = styled.footer`
  background-color: black;
  height: 410px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;