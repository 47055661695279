import React, {useState} from "react";
import {app} from "../../app/config/firebaseConfig";
import {useNavigate} from "react-router-dom";

import {
    Column1,
    Column2,
    Form,
    FormButton,
    FormContent,
    FormH1,
    FormInput,
    FormLabel,
    Image,
    ImageWrapper,
    Row,
    SubTitle,
    TopLine,
    WaitlistContainer, WaitlistText,
    WaitlistWrapper,
} from "./WaitlistElements";
import {Button} from "../ButtonElements";
import {ArrowForward, ArrowRight} from "../Home/HeroSection/HeroElements";

const Waitlist = () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [loader, setLoader] = useState(false);
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        app
            .collection("waitlist")
            .add({
                email: email,
                name: name,
                phone: phone,
            })

            // await setDoc(doc(db, "waitlist"), {
            //     name: name,
            //     email: email,
            //     country: phone
            // })
            .then(() => {
                setLoader(false);
                alert("Thank you for joining our waitlist");
            })
            .catch((error) => {
                alert(error.message);
                setLoader(false);
            });
        setName("");
        setEmail("");
        setPhone("");
    };

    return (
        <WaitlistContainer>
            <WaitlistWrapper>
                <Row>
                    <Column1>
                        <ImageWrapper>
                            <Image src="waitlistcapy.png"/>
                        </ImageWrapper>
                    </Column1>
                    <Column2>
                        <FormContent>
                            <TopLine className="title">Reserve your place in line</TopLine>
                            <Form className='form' onSubmit={handleSubmit}>
                                <FormH1>Join the Waitlist</FormH1>
                                <FormLabel>Email</FormLabel>
                                <FormInput
                                    required
                                    placeholder='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <FormLabel>Name </FormLabel>
                                <FormInput
                                    required
                                    placeholder='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <FormLabel>Phone </FormLabel>
                                <FormInput
                                    required
                                    placeholder='phone (optional)'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <Button
                                    to='submit'
                                    onMouseEnter={onHover}
                                    primary='true'
                                    dark='true'
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}
                                >
                                    Submit {hover ? <ArrowForward/> : <ArrowRight/>}
                                </Button>
                                <SubTitle> This information will only be shared with Atomic Rodents.
                                </SubTitle>
                            </Form>
                        </FormContent>
                    </Column2>
                </Row>


                {/*<Icon to='/'>smallBlox Waitlist</Icon>*/}

            </WaitlistWrapper>
        </WaitlistContainer>
    );
};
export default Waitlist;
