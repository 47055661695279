export const tokenSectionData = {
    id: "token",
    lightBackground: true,
    lightText: false,
    lightTextDescription: true,
    topLine: "Freedom",
    headline: "Giving Control to the Business",
    description: "test",
    buttonLabel: "Learn More",
    pathName: "/about-us",
    imgStart: false,
    img: require("../../images/smallbusiness.svg").default,
    alt: "BTC",
    dark: true,
    primary: true,
    darkText: false,
};
