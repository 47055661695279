import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {tokenSectionData} from "./Data";

import {Card, HeadingH1, LikeButton, PdfFrame, WhitepaperContainer, WhitepaperWrapper,} from "./WhitepaperElements";
import TokenParallax from "../Tokenomics/HeroSection/TokenParallax";

function Whitepaper() {

    const navigate = useNavigate();

    const [isPdfOpen, setIsPdfOpen] = useState(false);

    useEffect(() => {
        const handleClick = () => {
            setIsPdfOpen(false);
        };

        if (isPdfOpen) {
            document.addEventListener("click", handleClick);
        } else {
            document.removeEventListener("click", handleClick);
        }

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [isPdfOpen]);

    const handleCardClick = () => {
        setIsPdfOpen(!isPdfOpen);
    };

    return (
        <WhitepaperContainer>
            <WhitepaperWrapper>
                <HeadingH1 className="title">{tokenSectionData.topLine}</HeadingH1>

                <Card onClick={handleCardClick} isPdfOpen={isPdfOpen}>
                    <LikeButton src="smallblox.png" alt="Like Button"/>
                </Card>
                {isPdfOpen && (
                    <PdfFrame src="smallBlox-whitepaper.pdf" title="smallBlox Whitepaper"/>
                )}
            </WhitepaperWrapper>
            <TokenParallax/>
        </WhitepaperContainer>

    );
}

export default Whitepaper;
