import React, {useState} from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import HeroSection from "../components/Home/HeroSection";
import TokenSection from "../components/Home/TokenSection";
import CompanySection from "../components/Home/CompanySection";
import WaitlistSection from "../components/WaitlistSection";
import TechnologySection from "../components/Company/AboutSection";
import ScrollToTop from "../components/ScrollToTop";

function Home() {
    const [isOpen, SetIsOpen] = useState(false);

    const toggle = () => {
        SetIsOpen(!isOpen);
    };
    return (
        <>
            <SideBar isOpen={isOpen} toggle={toggle}/>
            <NavBar toggle={toggle} transparentOnScroll/>
            <HeroSection/>
            <TokenSection/>
            {/*<TechnologySection/>*/}
            {/*<CompanySection/>*/}
            <WaitlistSection/>
            <ScrollToTop/>
            <Footer/>
        </>
    );
}

export default Home;
