import React, {useEffect, useState} from "react";
import {ParallaxContainer, ParallaxImage} from "./TokenParallaxElements";

function TokenParallax() {

    const [backgroundPosition, setBackgroundPosition] = useState("50%");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setBackgroundPosition(`${50 - (window.scrollY / 5)}%`);
        });
    }, []);

    return (
        <ParallaxContainer>
            <ParallaxImage image="smallblox-tokens2.png"
                           style={{backgroundPositionY: backgroundPosition}}
            />
        </ParallaxContainer>
    );
}

export default TokenParallax;


