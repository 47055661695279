import React, {useEffect, useRef, useState} from "react";
import {IconContext} from "react-icons/lib";
import {FaBars} from "react-icons/fa";
import {animateScroll as scroll} from "react-scroll";

import {
    ArrowDown,
    MobileIcon,
    Navbar,
    NavBarContainer,
    NavBarLogo,
    NavItem,
    NavLinks,
    NavMenu,
} from "./NavBarElements";
import {useNavigate} from "react-router-dom";
import TokenDropdown from "./TokenDropdown";
import CompanyDropdown from "./CompanyDropdown";
import * as PropTypes from "prop-types";

function StyledLink(props) {
    return null;
}

StyledLink.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node
};
const NavBar = ({toggle, transparentOnScroll, isTransparent}) => {
    let navigate = useNavigate();

    const [scrollNav, setScrollNav] = useState(false);
    const [isTokenOpen, setIsTokenOpen] = useState(false);
    const [isCompanyOpen, setIsCompanyOpen] = useState(false);
    const [dropdownActive, setDropdownActive] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
        return () => {
            window.removeEventListener('scroll', changeNav);
        };
    }, []);


    useEffect(() => {
        document.addEventListener("click", closeAllDropdowns);
        return () => {
            document.removeEventListener("click", closeAllDropdowns);

            document.removeEventListener("click", closeAllDropdowns);
        };
    }, []);



    const companyRef = useRef(null);
    const tokenRef = useRef(null);

    const closeAllDropdowns = (event) => {
        if (
            (!tokenRef.current || !tokenRef.current.contains(event.target)) &&
            (!companyRef.current || !companyRef.current.contains(event.target))
        ) {
            setIsTokenOpen(false);
            setIsCompanyOpen(false);
        }
    };

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    const onCompanyMouseEnter = () => {
        setIsCompanyOpen(!isCompanyOpen);
        companyHandleDropdownClick();
        setIsTokenOpen(false);
        scroll.scrollToTop();
    }

    const onTokenMouseEnter = () => {
        setIsTokenOpen(!isTokenOpen);
        tokenHandleDropdownClick();
        setIsCompanyOpen(false);
        scroll.scrollToTop();
    }

    const transparentToggle = () => {
        setTransparent(!transparent);
    };

    const tokenHandleDropdownClick = () => {
        setDropdownActive(!dropdownActive);
    };

    const companyHandleDropdownClick = () => {
        setDropdownActive(!dropdownActive);
    };


    const [transparent, setTransparent] = useState(true);

    useEffect(() => {
        if (transparentOnScroll) {
            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [transparentOnScroll]);

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setTransparent(true);
        } else {
            setTransparent(false);
        }
    };

    return (
        <IconContext.Provider value={{color: "#fff"}}>
            <Navbar transparent={transparent && transparentOnScroll || isTransparent}>
                <NavBarContainer onMouseLeave={() => closeAllDropdowns}>
                    <NavBarLogo to='/' onClick={toggleHome}>
                        <img
                            src='/cybercapy-transformed.png'
                            alt='logo'
                            style={{marginRight: 10, width: 50}}
                            // style="width: 100px; height: 50px; margin-right: 20px; float: right;"
                        />
                        Atomic Capy AI
                    </NavBarLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars/>
                    </MobileIcon>

                    <NavMenu onMouseLeave={closeAllDropdowns}>
                        {/*Home NavItem*/}
                        <NavItem>
                            <NavLinks
                                to='home'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-90}
                                onClick={() => navigate('/')}
                                onMouseEnter={closeAllDropdowns}
                            >
                                HOME
                            </NavLinks>
                        </NavItem>
                        {/*Our Token NavItem*/}
                        <NavItem>
                            <NavLinks
                                // to='token'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-90}
                                onMouseEnter={onTokenMouseEnter}
                                onClick={() => navigate('/token#hero')}
                                className={dropdownActive ? "dropdown-active" : ""}
                            >
                                OUR TOKEN <ArrowDown className="arrow-icon"/>
                            </NavLinks>

                            {isTokenOpen &&
                            <TokenDropdown
                                isTokenOpen={isTokenOpen}
                                setIsTokenOpen={setIsTokenOpen}
                                ref={tokenRef}
                            />}
                        </NavItem>
                        {/*Company NavItem*/}
                        <NavItem>
                            <NavLinks
                                // to='company'
                                smooth={false}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-90}
                                onMouseEnter={onCompanyMouseEnter}
                                onClick={() => navigate('/company#hero')}
                                // className={dropdownActive ? "dropdown-active" : ""}
                            >
                                COMPANY <ArrowDown className="arrow-icon"/>
                            </NavLinks>
                            {isCompanyOpen &&
                            <CompanyDropdown
                                isCompanyOpen={isCompanyOpen}
                                setIsCompanyOpen={setIsCompanyOpen}
                                ref={tokenRef}
                            />}
                        </NavItem>

                        {/*Contact NavItem*/}
                        <NavItem>
                            <NavLinks
                                // to='contact'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-90}
                                onClick={() => navigate('/contact#hero')}
                                onMouseEnter={closeAllDropdowns}
                            >
                                CONTACT
                            </NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavBarContainer>
            </Navbar>
        </IconContext.Provider>
    );
};
export default NavBar;
