import {ReactComponent as CompleteIcon} from "../../images/completed.svg";
import {ReactComponent as IncompleteIcon} from "../../images/incomplete.svg";

import timelineElements from "./DataElements";

import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";
import {
    RoadmapContent,
    RoadmapH1,
    RoadmapH3,
    RoadmapH5,
    RoadmapH6,
    RoadmapP,
} from "./RoadmapElements";

function Roadmap() {
    let completeIconStyles = {background: "#3c81f0"};
    let incompleteIconStyles = {background: "#3c81f0"};
    let arrowColor = {background: "#3c81f0"};

    return (
            <RoadmapContent>
                <RoadmapH1 className="title">FEATURE ROADMAP</RoadmapH1>
                <RoadmapH6>Let's pave the way towards the future.</RoadmapH6>
                <VerticalTimeline
                    lineColor = "#062961">
                    {timelineElements.map((element) => {
                        let isCompletedIcon = element.icon === "complete";
                        let showButton =
                            element.buttonText !== undefined &&
                            element.buttonText !== null &&
                            element.buttonText !== "";

                        return (
                            <VerticalTimelineElement
                                key={element.key}
                                date={element.date}
                                contentStyle={isCompletedIcon ? completeIconStyles : incompleteIconStyles}
                                contentArrowStyle={arrowColor}
                                dateClassName="date"
                                iconStyle={isCompletedIcon ? completeIconStyles : incompleteIconStyles}
                                icon={isCompletedIcon ? <CompleteIcon/> : <IncompleteIcon/>}
                            >
                                <RoadmapH3 className="vertical-timeline-element-title">
                                    {element.title}
                                </RoadmapH3>
                                <RoadmapH5 className="vertical-timeline-element-subtitle">
                                    {element.location}
                                </RoadmapH5>
                                <RoadmapP id="description">
                                    <ul>
                                        <li>{element.description1}</li>
                                        <li>{element.description2}</li>
                                        <li>{element.description3}</li>
                                        <li>{element.description4}</li>
                                    </ul>
                                </RoadmapP>
                                {showButton && (
                                    <a
                                        className={`button ${
                                            isCompletedIcon ? "workButton" : "schoolButton"
                                        }`}
                                        href="/"
                                    >
                                        {element.buttonText}
                                    </a>
                                )}
                            </VerticalTimelineElement>
                        );
                    })}
                </VerticalTimeline>
            </RoadmapContent>
    );
}

export default Roadmap;
