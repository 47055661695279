import {useNavigate} from 'react-router-dom';
import {tokenSectionData} from "./TokenData";
import {Button} from "../../ButtonElements";
import React, {useEffect, useState} from 'react'
import {
    ButtonWrapper,
    CardContainer,
    CardWrapper,
    Column1,
    Column2,
    Content, Heading, Heading1,
    Image,
    ImageWrapper,
    SubTitle,
    TextWrapper,
    Title,
    TokenContainer,
    TokenImage,
    TokenRow,
    TokenWrapper,
    TopLine,
} from "./TokenElements";
import CardSwitcher from "./CardSwitcher";
import Carousel from "./Carousel";

const Card = ({title, content, image}) => (
    <CardWrapper>
        <Image src={image}/>
        <Title>{title}</Title>
        <Content>{content}</Content>
    </CardWrapper>
);


function TokenSection() {
    const navigate = useNavigate();
    const [backgroundPosition, setBackgroundPosition] = useState("50%");
    const [scrollPosition, setScrollPosition] = useState(0);


    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <TokenContainer id='token' style={{backgroundPositionY: `${scrollPosition * 0.5}px`}}>
            <TokenImage image="capyspacebackground.png"
                        style={{backgroundPositionY: backgroundPosition}}
            />
            <TokenWrapper>
                <TokenRow imgStart={tokenSectionData.imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{tokenSectionData.topLine}</TopLine>
                            <SubTitle darkText={tokenSectionData.darkText}>{tokenSectionData.description}</SubTitle>
                            <ButtonWrapper>
                                <Button
                                    to='token'
                                    smooth={true}
                                    duration={500}
                                    onClick={() => navigate("/tokenomics")}
                                    spy={true}
                                    extact='true'
                                    offset={-80}
                                    primary={tokenSectionData.primary ? 1 : 0}
                                    dark={tokenSectionData.dark ? 1 : 0}
                                >
                                    {tokenSectionData.buttonLabel}
                                </Button>
                            </ButtonWrapper>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImageWrapper>
                            <Image src="capycyber001.png"
                            />
                        </ImageWrapper>
                    </Column2>
                </TokenRow>
            </TokenWrapper>
            <CardContainer>
                <Card title="Instantly Send and Receive Money "
                      content="All you need is a mobile number or QR code to send Bitcoin and other crypto.
                          The transaction is free when the sender and the recipient use the same currency."
                      image="capygoggles.png"/>

                <Card title="Secure Your Money"
                      content="Your money will never leave your custody. One of Capy AI's key focus is to ensure you have control and full custody of your money at all times during a transaction and always."
                      image="capyfacefont.png"/>


                <Card title="No Overhead"
                      content="Local customers and small businesses should be able to transact without third parties taking big fees and controlling their money."
                      image="cybercapyhat.png"/>

            </CardContainer>
            {/*<TextWrapper>*/}
            {/*    <TopLine>{tokenSectionData.topLine}</TopLine>*/}
            {/*</TextWrapper>*/}
            <Heading1>NFT COLLECTION</Heading1>

            <Carousel/>
        </TokenContainer>
    );

}

export default TokenSection;
