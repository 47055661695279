import styled from "styled-components";
import {MdKeyboardArrowRight, MdArrowForward} from "react-icons/md";


export const ParallaxContainer = styled.div`
  min-height: 500px;
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
      
    @media screen and (max-width: 768px) {
    height: 50vh;
    background-image: url(${props => props.image});
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    display: block;
  }
`;

export const ParallaxImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-attachment: fixed;
  background-image: url(${props => props.image});
  background-size: cover;
  width: 100%;
  height: 130%;
`;


export const HeroContainer = styled.div`
      background-image: url('smallblox-hero15.png');
      display: flex;
      justify-content: center;
      text-align: left;
      align-items: center;
      height: 850px;
      z-index: 1;   
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      :before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            180deg,
            rgba(252, 253, 255) 0%,
            rgba(252, 253, 255) 100%
          ),
          linear-gradient(180deg, rgba(252, 253, 255) 0%, transparent 100%);
        z-index: 2;
      }
`;

export const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Video = styled.video`
  width: 80%;
  height: 60%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 900px;
  flex-direction: column;
  align-items: top;
  margin-bottom: 350px
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 54px;
  text-align: left;
  max-width: 700px;
  
  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #3c81f0;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HeroButtonWrapper = styled.div`
  margin-top: 700px;
    margin-left: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ImageWrapper = styled.div`
  // max-width: 555px;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  // margin: 0 0 10px 0;
  padding-right: 0;
`;



export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled.input`
  padding: 20px;
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;