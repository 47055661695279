import styled, { keyframes } from 'styled-components';


export const TokenContainer = styled.div`
  position: relative;
  display: flex;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  
  @media screen and (max-width: 768px) {
    // padding: 100px 0;
  }
`;

export const TokenImage = styled.div`
  position: absolute;
  top: 26%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-attachment: fixed;
  width: 100%;
  background-image: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(${props => props.image});
  background-size: cover;
  background-position: center;
  height: 300vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);

`;

export const TokenWrapper = styled.div`
  top: 23%;
  display: grid;
  position: absolute;
  z-index: 2;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  // padding: 0 24px;
  justify-content: center;
`;

export const TokenRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({imgStart}) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};
      grid-gap: 25px;

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) =>
    imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  grid-area: col1;
`;

export const Column2 = styled.div`
  grid-area: col2;
`;

export const TextWrapper = styled.div`
`;

export const TopLine = styled.p`
  color: #00b8ff;
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? "#FFFFFF" : "#FFFFFF")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const SubTitle = styled.p`
  max-width: 640px;
  margin-bottom: 20px;
  font-size: 1.60rem;
  line-height: 40px;
  color: #808080;
  font-family: 'BR Cobane Regular', sans-serif;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 300px;
  justify-content: flex-start;
`;
export const ImageWrapper = styled.div`
  max-width: 850px;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: block;
  margin: 0 auto;
`;

export const CardContainer = styled.div`
  top: -4%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 2150px;
`;

export const CardWrapper = styled.div`  
  width: 450px;
  margin: 26px;
  text-align: left;
  background-color: transparent; 
  color: white;
  border-radius: 25px;
  border: 2px solid #d600ff;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2), 0px 0px 25px #d600ff;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translate(0, -25px);
    box-shadow: 10px 10px 40px rgba(0,0,0,0.3);
  }
`;

export const Title = styled.h5`
  font-size: 1.5rem;
  margin: 40px;
  color: #0047AB;
  font-weight: bold;

`;

export const Content = styled.p`
  font-size: 1.55rem;
  margin: 40px;
  color: #808080;
  font-family: 'BR Cobane Regular', sans-serif;
`;


export const Container = styled.div`
  width: 100%;
  height: 500px;
`;

export const rainbowAnimation = keyframes`
  0% {
    color: red;
  }
  50% {
    color: orange;
  }
  100% {
    color: yellow;
  }
`;


export const Heading1 = styled.h1`
  top: 2900px;
  position: absolute;
  z-index: 5;
  justify-content: center;
    align-items: center;
  font-size: 5rem;
  animation: ${rainbowAnimation} 3s linear infinite;
`;
