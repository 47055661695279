import React from "react";
import {useNavigate} from "react-router-dom";
import {ContextMenu, MenuItem} from 'react-contextmenu';

import {
    TokenDropDownContainer,
    TokenDropDownList,
    TokenDropDownListContainer,
    TokenListItem,
} from "./TokenDropdownElements";

const handleLinkClick = (event) => {
    event.preventDefault();
    window.open('/smallBlox-whitepaper.pdf', '_blank');
};

function TokenDropdown(isTokenOpen, setIsTokenOpen) {
    const navigate = useNavigate();
    const toggling = () => setIsTokenOpen(false);

    return (
        <TokenDropDownContainer>
            <TokenDropDownListContainer>
                <TokenDropDownList onMouseLeave={toggling}
                >
                    <TokenListItem
                        to='tokenomics'
                        onClick={() => navigate('/token#tokenomics')}
                    >
                        Tokenomics
                    </TokenListItem>
                    <TokenListItem
                        to='whitepaper'
                        onClick={handleLinkClick}
                    >
                        Whitepaper
                    </TokenListItem>
                </TokenDropDownList>
            </TokenDropDownListContainer>
        </TokenDropDownContainer>
    )
        ;
}

export default TokenDropdown;
