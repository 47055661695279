import styled from "styled-components";

export const TokenomicsContainer = styled.div`
  background-color: black;
  margin-top: 225px;
  padding: 200px 0;
  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const TokenomicsTableContainer = styled.div`
  background-color: black;
  display: flex;
  justify-content: center;
`;

export const TokenomicsGraphContainer = styled.div`
  background-color: black;
  display: flex;
  justify-content: center;
  padding: 100px;
`;

export const PieChartContainer = styled.div`
  flex: 1;
  margin-left: 20px;
`;

export const TableContainer = styled.div`
  flex: 1;
  margin-left: 300px;

`;

export const TokenomicsContent = styled.div`
  // display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;


export const TokenomicsH1 = styled.h1`
  padding: 70px 10px 35px 0px;
  color: #00b8ff;
  font-size: 44px;
  text-align: center;
  // margin-left: 140px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;


export const TokenomicsH2 = styled.h2`
  padding: 10px 10px 15px 10px;
  color: #062961;
  font-size: 44px;
  text-align: left;
  margin-left: 140px;
  margin-top: 150px;
  // text-shadow: 2px 2px;
  // -webkit-text-stroke-width: .5px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;


export const PieChart = styled.div`
  flex: 1;
  background-color: #FFC107;
  border-radius: 50%;
  width: 400px;
  height: 200px;
  display: inline-block;
  margin-right: 20px;
`;


export const TokenTable = styled.table`
  margin: 0;
  border-collapse: collapse;
  width: 400px;
  text-align: center;
`;

export const TokenRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

export const TokenData = styled.td`
  padding: 10px 0;
`;


export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;

  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #fff;
  }

  th {
    background-color: #fff;
  }

  tr:hover {
    background-color: #fff;
  }
`;