import React from "react";
import {useNavigate} from 'react-router-dom';

import {Button} from "../../ButtonElements";
import {
    ButtonWrapper,
    Column1,
    Column2,
    CompanyContainer,
    CompanyRow,
    CompanyWrapper,
    Image,
    ImageWrapper,
    SubTitle,
    TextWrapper,
    TopLine,
} from "./../CompanySection/CompanyElements";

import {companySectionData} from "./Data";


function CompanySection() {

    const history = useNavigate();

    return (
        <CompanyContainer lightBackground={companySectionData.lightBackground} id={companySectionData.id}>
            <CompanyWrapper>
                <CompanyRow imgStart={companySectionData.imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{companySectionData.topLine}</TopLine>
                            <SubTitle lightText={companySectionData.lightText}>{companySectionData.description}</SubTitle>
                            <ButtonWrapper>
                                <Button
                                    to='home'
                                    smooth={true}
                                    duration={500}
                                    onClick={() => history("/about-us")}
                                    spy={true}
                                    extact='true'
                                    offset={-80}
                                    primary={companySectionData.primary ? 1 : 0}
                                    dark={companySectionData.dark ? 1 : 0}
                                >
                                    {companySectionData.buttonLabel}
                                </Button>
                            </ButtonWrapper>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImageWrapper>
                            <Image src={companySectionData.img} alt={companySectionData.alt} />
                        </ImageWrapper>
                    </Column2>
                </CompanyRow>
            </CompanyWrapper>
        </CompanyContainer>

    );
}

export default CompanySection;
