import styled from "styled-components";
import {Link} from "react-scroll";

export const WaitlistContainer = styled.div`
  position: relative;
  background: ${({lightBackground}) =>
    lightBackground ? "#00b8ff" : "#00b8ff"};
  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const WaitlistWrapper = styled.div`
  z-index: 1;
  height: 360px;
  width: 100%;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  // padding: 0 24px;
  justify-content: center;
`;

export const WaitlistRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({imgStart}) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) =>
    imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;



export const TopLine = styled.p`
  color: #062961;
  font-size: 44px;
  overflow-wrap: normal;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;
export const Heading = styled.h1`
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
  position: relative;
  color: #FFFFFF
  justify-content: left;
  transform: translate(100px, 120px); /* Adjust the values as needed */
  
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const SubTitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({darkText}) => (darkText ? "#3c81f0" : "#fff")};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const ImageWrapper = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const WaitlistFormContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: flex-start; /* change from center to flex-start */
  justify-content: center;
  padding: 150px;
  box-sizing: border-box;
  text-align: center;
`;

export const WaitlistForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled.input`
  width: 170%;
  height: 30px;
  padding: 22px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
`;

export const WaitlistButton = styled.button`
  width: 50%;
  height: 40px;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  background-color: blue;
  color: white;
  font-size: 18px;
  cursor: pointer;
\`;
`;