import styled from "styled-components";
import {Link} from "react-scroll";

export const Container = styled.div`
  margin-top: 4000px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
`;

export const CardContainer = styled.div`
  display: flex;
  width: 1300px;
  height: 700px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
  transform: translateZ(10px) scale(0.95);
  overflow: hidden;
  transform: perspective(1000px);
  // z-index: 0;

`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: 20px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

export const Description = styled.div`
  font-size: 16px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

export const Button = styled(Link)`
  border-radius: 3px;
  background: ${({primary}) => (primary ? "#062961" : "#3c81f0")};
  white-space: nowrap;
  padding: ${({big}) => (big ? "14px 48px" : "12px 30px")};
  color: ${({dark}) => (dark ? "#fff" : "#3c81f0")};
  font-size: ${({fontBig}) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? "#808080" : "#3c81f0")};
  }
`;


export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  height: 50px;
  border-radius: 20px;
  background-color: #062961;
  cursor: pointer;
  position: absolute;
  margin-bottom: 500px;
  z-index:4;
`;

export const Option = styled.div`
  width: 100%;
  height: 100%;
  background-color: #062961;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "Orbitron Expanded", Orbitron, sans-serif;
  border-radius: 20px 20px 20px 20px;
  background-color: ${props => props.selectedCard ? '#5AC8FA' : '#062961'};
  transition: background-color 0.3s ease-in-out;
`;