import { initializeApp } from "firebase/app";
import {} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC8fQ3JaQ-QTb4MarJJVkuCtAiQ44gTM7Y",
  authDomain: "smallblox.firebaseapp.com",
  projectId: "smallblox",
  storageBucket: "smallblox.appspot.com",
  messagingSenderId: "229432048386",
  appId: "1:229432048386:web:955f5fedcdbb9d8eace01b",
  measurementId: "G-XLP21ZRPZ0",
};

export const app = initializeApp(firebaseConfig);
