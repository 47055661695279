import styled from "styled-components";
import { Link } from "react-router-dom";

export const WaitlistContainer = styled.div`
  background-color: black;
  position: relative;
  overflow: hidden;
  justify-content: center;
  // margin-bottom: 50px:

`;

export const WaitlistWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
    height: 80%;
  }
`;

export const WaitlistText = styled.p`
 color: "#880808"
`;

export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: #FFF;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
    padding: 10px;
  }
`;

export const Form = styled.form`
  background: #00b8ff;
  max-width: 600px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 30px 32px;
  border-radius: 5spx;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 400px) {
    padding: 32px 32px;
  }
`;

export const FormH1 = styled.h1`
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #fff;
`;

export const FormInput = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;
`;

export const FormButton = styled.button`
  background: #01bf71;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

export const FormText = styled.span`
  text-align: center;
  margin-top: 24px;
  color: #fff;
  font-size: 14px;
`;


export const Row = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({imgStart}) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};
      grid-gap: 0px;


  @media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) =>
    imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 100px;
  margin-top: 45px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 100px;
  margin-top: 5px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 840px;
  margin-left: 400px;
  color: #062961;
`;

export const TopLine = styled.p`
  margin-top: 70px;
  color: #d600ff;
  font-size: 44px;
  font-weight: 700;
  max-width: 540px;
  margin-left: 100px;
  margin-bottom: 50px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? "#f7f8fa" : "#3c81f0")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const SubTitle = styled.p`
  max-width: 640px;
  font-size: 1.10rem;
  margin-top: 15px;
  line-height: 20px;
  color: #FFF;
  font-family: 'BR Cobane Regular', sans-serif;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const ImageWrapper = styled.div`
  max-width: 850px;
  height: 100%;
`;

export const Image = styled.img`
  width: 90%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: block;
  margin: 0 auto;
`;

