import styled from 'styled-components';

export const GalleryContainer = styled.div`
  background-color: black;
  display: flex;
  // flex-wrap: wrap;
  z-index: 1;
  justify-content: center;
  position: relative;
  margin-top: 200%;
  width: 80%;
`;

export const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  color: #fff;
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
`;

export const Button = styled.button`
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
`;

export const ArrowButton = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.direction === 'left' ? 'left: 0;' : 'right: 0;')}
  height: 200px;
  background-color: transparent; 
  border: none; 
  &:hover {
    background-color: transparent; 
  }
  &:focus {
    outline: none; 
  }
  font-size: 70px; 
  color: white;

`;
