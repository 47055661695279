import React, {useState} from "react";
import SideBar from "../../components/SideBar";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import HeroSection from "../../components/Tokenomics/HeroSection";
import WaitlistSection from "../../components/WaitlistSection";
import TokenomicsSection from "../../components/Tokenomics/TokenomicsSection";
import TokenParallax from "../../components/Tokenomics/HeroSection/TokenParallax";

function TokenPage() {
    const [isOpen, SetIsOpen] = useState(false);

    const toggle = () => {
        SetIsOpen(!isOpen);
    };
    return (
        <>
            <SideBar isOpen={isOpen} toggle={toggle}/>
            <NavBar toggle={toggle}  />
            <HeroSection/>
            <TokenParallax/>
            <TokenomicsSection/>
            <WaitlistSection/>
            <Footer/>
        </>
    );
}

export default TokenPage;
