import styled from "styled-components";
import {MdKeyboardArrowRight, MdArrowForward} from "react-icons/md";

export const RoadmapContainer = styled.div`
  background: ${({lightBackground}) =>
    lightBackground ? "#000000" : "#000000"};
  // display: flex;
  // justify-content: left;
  text-align: left;
  // align-items: center;
  padding: 0 30px;
  height: 800px;
  // position: relative;
  z-index: 1;

  :before {
    // position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(252, 253, 255) 0%,
        rgba(252, 253, 255) 100%
      ),
      linear-gradient(180deg, rgba(252, 253, 255) 0%, transparent 100%);
    z-index: 2;
  }
`;


export const RoadmapVerticalTimelineElement = styled.div`
  // z-index: 3;
  // max-width: 800px;
  // position: absolute;
  // padding: 8px 24px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // RoadmapVerticalTimelineElement: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export const RoadmapContent = styled.div`
  // z-index: 3;
  // max-width: 800px;
  // position: absolute;
  padding: 8px 24px 150px;
  // display: flex;
  // flex-direction: column;
  // align-items: 
  background: #000000
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

`;

export const RoadmapH1 = styled.h1`
  padding: 10px 10px 15px 10px;
  color: #00b8ff;
  font-size: 64px;
  text-align: left;
  margin-left: 140px;
  margin-top: 50px;
  // text-shadow: 2px 2px;
  // -webkit-text-stroke-width: .5px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;


export const RoadmapH3 = styled.h3`
  padding: 10px 10px 10px 140px;
  color: #fff;
  font-size: 34px;
  tr:nth-child(odd)
  text-align: right;
  background-color: #062961;
  // margin-left: 140px;
  // margin-top: 50px;



  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;


export const RoadmapH5 = styled.h5`
  color: #062961
  font-size: 24px;
  text-align: left;


  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const RoadmapH6 = styled.h6`
  padding: 1px 1px 100px;
  color: #3c81f0;
  font-size: 25px;
  text-align: left;
  margin-left: 150px;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const RoadmapP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: left;
  max-width: 600px;
  padding: 5px 10px 15px 40px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const RoadmapButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
