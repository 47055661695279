import React, {useState} from "react";
import ScrollToTop from "../components/ScrollToTop";
import Waitlist from "../components/Waitlist";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";

function WaitlistPage() {
    const [isOpen, SetIsOpen] = useState(false);
    const [isTransparent, SetIsTransparent] = useState(false);

    const transparent = () => {
        SetIsTransparent(!isTransparent);
    };

    const toggle = () => {
        SetIsOpen(!isOpen);
    };

    return (
        <>
            <SideBar isOpen={isOpen} toggle={toggle}/>
            <NavBar toggle={toggle} />
            <Waitlist/>
            <ScrollToTop/>
            <Footer/>
        </>
    );
}
<ScrollToTop/>

export default WaitlistPage;
