export const companySectionData = {
    id: "company",
    lightBackground: true,
    lightText: false,
    lightTextDescription: true,
    topLine: "The Company",
    description: "Welcome to smallBlox, a payment processing company that is dedicated to making transactions secure, fast and affordable for businesses of all sizes. Our team consists of experienced professionals who have a passion for creating innovative solutions in the fintech industry. With a focus on customer satisfaction and a commitment to using the latest technology, we are committed to delivering a seamless experience to our clients. Whether you're a small start-up or a large enterprise, our payment processing services will help streamline your transactions and make your business more efficient. Contact us today to learn more about how we can help take your business to the next level.",
    buttonLabel: "Learn More",
    pathName: "/about-us",
    imgStart: false,
    img: require("../../../images/smallbusiness.svg").default,
    alt: "BTC",
    dark: true,
    primary: true,
    darkText: false,
};
