import React, {useState} from "react";
import SideBar from "../../components/SideBar";
import NavBar from "../../components/NavBar";
import ScrollToTop from "../../components/ScrollToTop";
import Footer from "../../components/Footer";
import Roadmap from "../../components/Roadmap";
import {PageContainer} from "../PageElements";

function RoadmapPage() {
    const [isOpen, SetIsOpen] = useState(false);

    const toggle = () => {
        SetIsOpen(!isOpen);
    };
    return (
        <PageContainer>
            <SideBar isOpen={isOpen} toggle={toggle}/>
            <NavBar toggle={toggle}/>
            <Roadmap/>
            <ScrollToTop/>
            <Footer/>
        </PageContainer>
    );
}

export default RoadmapPage;
