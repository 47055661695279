import styled from "styled-components";


export const AboutContainer = styled.div`
  position: relative;
  background-color: black;
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;
export const AboutWrapper = styled.div`
  height: 300vh;
  display: grid;
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: black;
  justify-content: center;

`;


export const AboutImage = styled.div`
  position: absolute;
  top: 26%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-attachment: fixed;
  width: 100%;
  background-image: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(${props => props.image});
  background-size: cover;
  background-position: center;
  height: 300vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

export const AboutRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({imgStart}) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};
      grid-gap: 25px;

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) =>
    imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 100px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 100px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;
export const TopLine = styled.p`
  color: #062961;
  font-size: 44px;
  overflow-wrap: normal;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 46px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? "#f7f8fa" : "#3c81f0")};

  @media sceen and (max-width: 480px) {
    font-size: 32px;
  }
  font-family: 'BR Cobane Regular', sans-serif;

`;

export const SubTitle = styled.p`
  max-width: 640px;
  margin-bottom: 100px;
  font-size: 1.60rem;
  line-height: 40px;
  color: #5A5A5A;
  font-family: 'BR Cobane Regular', sans-serif;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const ImageWrapper = styled.div`
  max-width: 655px;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
