import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Button} from "../../ButtonElements";

import {
    ArrowForward,
    ArrowRight,
    HeroButtonWrapper,
    HeroContainer,
    HeroContent,
    HeroH1, ParallaxContainer, ParallaxImage, Video,
    VideoContainer,
} from "./HeroElements";

function HeroSection() {
    const [hover, setHover] = useState(false);

    const navigate = useNavigate();

    const onHover = () => {
        setHover(!hover);
    };

    const [backgroundPosition, setBackgroundPosition] = useState("50%");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setBackgroundPosition(`${50 - (window.scrollY / 5)}%`);
        });
    }, []);

    return (
        // <HeroContainer id='home'>
            <ParallaxContainer id='home'>
                <ParallaxImage image="capyforest.png"
                               style={{backgroundPositionY: backgroundPosition}}
                />
            <HeroContent>
                {/*<HeroH1>TIME TO PROCESS YOUR OWN PAYMENTS</HeroH1>*/}
                <HeroButtonWrapper>
                    <Button
                        to='waitlist'
                        onMouseEnter={onHover}
                        onClick={() => navigate('/waitlist')}
                        primary='true'
                        dark='true'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                    >
                        Join the Waitlist {hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </HeroButtonWrapper>
            </HeroContent>
            </ParallaxContainer>
    );
}

export default HeroSection;
