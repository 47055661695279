import styled from "styled-components";
import {MdArrowForward, MdKeyboardArrowRight} from "react-icons/md";

export const HeroContainer = styled.div`
      background-color: black;
      display: flex;
      justify-content: center;
      text-align: left;
      align-items: center;
      height: 850px;
      width: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    
      :before {
        position: absolute;
        top: 0;
        left: 0;
        right:      0;
        bottom: 0;
        background: linear-gradient(
            180deg,
            rgba(252, 253, 255) 0%,
            rgba(252, 253, 255) 100%
          ),
          linear-gradient(180deg, rgba(252, 253, 255) 0%, transparent 100%);
        z-index: 2;
      }
`;


export const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ParallaxImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-attachment: fixed;
  background-color: black;
  background-image: url(${props => props.image});
  background-size: cover;
  width: 100%;
  height: 130%;
  
   @media screen and (max-width: 768px) {
    height: 50vh;
    background-image: url(${props => props.image});
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    display: block;
   }
`;

export const VideoBackground = styled.video`
  width: 100%;
  height: 100%;
  -o-o-object-fit: cover;
  object-fit: cover;
  background: #fff;
`;

export const HeroContent = styled.div`
  z-index: 1;
`;

export const HeroH1 = styled.h1`
  color: #000000;
  font-size: 54px;
  text-align: left;
  margin-left: -500px;
  max-width: 800px;
  margin-bottom: 100px;
  text-shadow: -2px -2px 0 #00b8ff, 2px -2px 0 #00b8ff, -2px 2px 0 #00b8ff, 2px 2px 0 #00b8ff;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #3c81f0;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HeroButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ImageWrapper = styled.div`
  // max-width: 555px;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  // margin: 0 0 10px 0;
  padding-right: 0;
`;



export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled.input`
  padding: 20px;
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;