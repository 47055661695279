import styled from "styled-components";

export const CompanyDropDownContainer = styled("div")`
  width: flex;
  margin: 0 auto;
  text-align: center;
`;

export const CompanyDropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.2rem;
  color: #3faffa;
  background: #ffffff;
`;

export const CompanyDropDownListContainer = styled("div")`
  width: flex;
  margin: 0 auto;

`;

export const CompanyDropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  padding-right: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #000000;
  font-size: 1.2rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;


export const CompanyListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  &:hover {
    background-color: #00b8ff;
    
  }
`;
