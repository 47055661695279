import styled from "styled-components";

export const ParallaxContainer = styled.div`
  position: relative;
  min-height: 500px;
`;

export const ParallaxImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-attachment: fixed;
  background-image: url(${props => props.image});
  background-size: cover;
  width: 100%;
  height: 100%;
  // margin-top: 4000px
  // background-image: url('smallblox-hero2.png');
`;