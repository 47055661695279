import styled from "styled-components";
import {Link as LinkRouter} from "react-router-dom";
import {Link as LinkScroll} from "react-scroll";
import {MdKeyboardArrowDown} from "react-icons/md";


export const Navbar = styled.nav`
  background-color: ${props => props.transparent ? 'transparent' : '#000000'};
  color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: ${props => props.transparent ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)'};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
`;

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  margin-top: 0;
  margin-bottom: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1rem;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;


export const NavTransparent = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.scrollNav ? "#0047AB" : 'rgba(255, 255, 255, 0)'};
  transition: background-color 0.5s ease;
  height: 90px;
  margin-top: 0;
  margin-bottom: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1rem;
  z-index: 10;
  box-shadow: ${(props) => props.scrollNav ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none'};

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;


export const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 90px;
  z-index: 10;
  width: 100%;
  max-width: 1400px;
`;

export const NavBarLogo = styled(LinkRouter)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 14px;
  font-weight: bold;
  text-decoration: none;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  justify-self: flex-end;
  float:right;
  align-items: right;
  list-style: none;
  justify-content: left;
  text-align: right;
  align-content: right;
  right:90px;
  position: absolute;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkScroll)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  
  &:hover {
    border-bottom: 5px solid #00b8ff;
    color: #00b8ff;
    .arrow-icon {
      transform: rotate(180deg);
    }
  }
`;

export const NavButton = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;


export const NavButtonLink = styled(LinkRouter)`
  border-radius: 3px;
  background: #fff;
  white-space: nowrap;
  padding: 10px 22px;
  color: #000080;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #0fff;
    color: #000080;
  }
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  margin-left: 8px;
  font-size: 40px;
  height: 100%;
  transform: rotate(0deg);
  transition: all 0.1s ease-in-out;
  
  &.active {
    transform: rotate(-180deg);
  }
  &:hover {
    transform: rotate(180deg);
  }
`;
