import React from "react";
import {SideBarContainer, SideBarLink, SideBarMenu, SideBarWrapper,} from "./SideBarElements";
import {SocialIconLink, SocialIcons} from "../Footer/FooterElements";
import {FaFacebook, FaInstagram, FaTelegram, FaTwitter, FaYoutube} from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const SideBar = ({isOpen, toggle}) => {

    const navigate = useNavigate();

    return (
        <SideBarContainer isOpen={isOpen} onClick={toggle}>
            {/*<NavBarLogo to='/' onClick={toggle}>*/}
            {/*    <img*/}
            {/*        src='/smallblox.png'*/}
            {/*        alt='logo'*/}
            {/*        style={{marginRight: 10}}*/}
            {/*    />*/}
            {/*    smallBlox*/}
            {/*</NavBarLogo>*/}
            {/*<Icon onClick={toggle}>*/}
            {/*    <CloseIcon/>*/}
            {/*</Icon>*/}
            <SideBarWrapper>
                <SideBarMenu>
                    <SideBarLink to='home' onClick={() => navigate('/')}>
                        HOME
                    </SideBarLink>
                    <SideBarLink to='ourToken' onClick={() => navigate('/tokenomics')}>
                        OUR TOKEN
                    </SideBarLink>
                    <ul>
                        <SideBarLink to='tokenomics' onClick={() => navigate('/tokenomics')}>
                            Tokenomics
                        </SideBarLink>
                        <SideBarLink to='whitepaper' onClick={() => navigate('/whitepaper')}>
                            Whitepaper
                        </SideBarLink>
                    </ul>
                    <SideBarLink to='company' onClick={() => navigate('/company')}>
                        COMPANY
                    </SideBarLink>
                    <ul>
                        <li><SideBarLink to='about-us' onClick={() => navigate('/about-us')}>
                            About Us
                        </SideBarLink></li>
                        <li><SideBarLink to='roadmap' onClick={() => navigate('/roadmap')}>
                            Roadmap
                        </SideBarLink></li>
                    </ul>
                    <SideBarLink to='contact' onClick={toggle}>
                        CONTACT
                    </SideBarLink>
                </SideBarMenu>

                <SocialIcons>
                    <SocialIconLink href='//www.facebook.com'
                                    target='_blank'
                                    aria-label='Facebook'>
                        <FaFacebook/>
                    </SocialIconLink>

                    <SocialIconLink href=''
                                    target='_blank'
                                    aria-label='Instagram'>
                        <FaInstagram/>
                    </SocialIconLink>
                    <SocialIconLink href='https://t.me/smallBlox'
                                    target='_blank'
                                    aria-label='Telegram'>
                        <FaTelegram/>
                    </SocialIconLink>
                    <SocialIconLink href='https://twitter.com/1smallBlox1'
                                    target='_blank'
                                    aria-label='Twitter'>
                        <FaTwitter/>
                    </SocialIconLink>
                    <SocialIconLink href='/'
                                    target='_blank'
                                    aria-label='YouTube'>
                        <FaYoutube/>
                    </SocialIconLink>
                </SocialIcons>
            </SideBarWrapper>
        </SideBarContainer>
    );
};

export default SideBar;


