import React, {useState} from 'react';
import {
    ArrowButton,
    Button,
    Description,
    GalleryContainer,
    GalleryImage,
    TextContainer,
    Title
} from "./GalleryElements";
import {TopLine} from "./TokenElements";
import {tokenSectionData} from "./TokenData";


const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
        {
            id: 1,
            src: 'cybercapy.png',
            title: 'Image 1',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            link: 'https://www.example.com',
        },
        {
            id: 2,
            src: 'cybercapy2.png',
            title: 'Image 2',
            description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            link: 'https://www.example.com',
        },
        {
            id: 3,
            src: 'capyforest.png',
            title: 'Image 3',
            description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            link: 'https://www.example.com',
        },
    ];

    const handlePrevClick = () => {
        setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
    };

    const handleNextClick = () => {
        setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
    };

    return (
        <GalleryContainer>
            <GalleryImage src={images[currentIndex].src} alt={images[currentIndex].title} />
            <TextContainer>
                <Title>{images[currentIndex].title}</Title>
                <Description>{images[currentIndex].description}</Description>
                <Button onClick={() => window.open(images[currentIndex].link)}>Click Here</Button>
            </TextContainer>
            <ArrowButton direction="left" onClick={handlePrevClick}>
                {'<'}
            </ArrowButton>
            <ArrowButton direction="right" onClick={handleNextClick}>
                {'>'}
            </ArrowButton>
        </GalleryContainer>
    );
};

export default Carousel;