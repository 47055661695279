export const tokenSectionData = {
    id: "token",
    lightBackground: true,
    lightText: false,
    lightTextDescription: true,
    topLine: "The CapyAI \nToken",
    description: "CapyAI is a cryptocurrency token built on the Polygon network " +
        "and the Ethereum blockchain. What sets CapyAI Token apart is that it was " +
        "primarily created using state-of-the-art AI. By leveraging the power of " +
        "cutting-edge machine learning algorithms, CapyAI Token has been designed to be a" +
        " versatile payment option that can be used for everyday transactions. " +
        "The development of CapyAI Token is unique in that AI has been used extensively to create " +
        "and fine-tune the token's features and functionality, making it a truly groundbreaking achievement " +
        "in the world of cryptocurrency. This innovative approach to development is designed to help ensure " +
        "that CapyAI Token is a reliable and effective payment option that can meet the needs of a wide range of users." +
        " By harnessing the power of AI, " +
        "CapyAI Token aims to offer a truly unique and user-friendly payment experience for individuals and businesses alike.",
    buttonLabel: "Learn More",
    pathName: "/tokenomics",
    imgStart: false,
    img: require("../../../images/cybercapy2.png").default,
    alt: "BTC",
    dark: true,
    primary: true,
    darkText: false,
};
