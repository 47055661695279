import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Button} from "../../ButtonElements";

import {ArrowForward, ArrowRight, HeroButtonWrapper, HeroContainer, HeroContent, HeroH1,} from "./HeroElements";
import TokenParallax from "./TokenParallax";
import {ParallaxImage} from "../../Home/HeroSection/HeroElements";
import {PdfFrame} from "../../Whitepaper/WhitepaperElements";

function HeroSection() {
    const [hover, setHover] = useState(false);
    const [backgroundPosition, setBackgroundPosition] = useState("50%");

    const navigate = useNavigate();

    const onHover = () => {
        setHover(!hover);
    };

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setBackgroundPosition(`${50 - (window.scrollY / 5)}%`);
        });
    }, []);

    const [isPdfOpen, setIsPdfOpen] = useState(false);

    useEffect(() => {
        const handleClick = () => {
            setIsPdfOpen(false);
        };

        if (isPdfOpen) {
            document.addEventListener("click", handleClick);
        } else {
            document.removeEventListener("click", handleClick);
        }

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [isPdfOpen]);

    const handleCardClick = () => {
        setIsPdfOpen(!isPdfOpen);
    };
    return (
        <HeroContainer id='hero'>
            <ParallaxImage image="ourtoken.png"
                           style={{backgroundPositionY: backgroundPosition}}
            />
            <HeroContent>
                <HeroH1>OUR TOKEN - CAPYAI</HeroH1>
                <HeroButtonWrapper>
                    <Button
                        to='whitepaper'
                        onMouseEnter={onHover}
                        onClick={handleCardClick} isPdfOpen={isPdfOpen}
                        primary='true'
                        dark='true'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                    >
                        Whitepaper {hover ? <ArrowForward/> : <ArrowRight/>}
                        {isPdfOpen && (
                            <PdfFrame src="smallBlox-whitepaper.pdf" title="smallBlox Whitepaper"/>
                        )}
                    </Button>

                </HeroButtonWrapper>

            </HeroContent>
        </HeroContainer>


    );
}

export default HeroSection;
