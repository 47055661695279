import React from "react";
import {useNavigate} from "react-router-dom";

import {
    CompanyDropDownContainer,
    CompanyDropDownList,
    CompanyDropDownListContainer,
    CompanyListItem,
} from "./CompanyDropdownElements";


function CompanyDropdown(toggle, isCompanyOpen, setIsCompanyOpen) {
    const navigate = useNavigate();
    const toggling = () => setIsCompanyOpen(false);


    return (
        <CompanyDropDownContainer>
            <CompanyDropDownListContainer>
                <CompanyDropDownList onMouseLeave={toggling}
                >
                    <CompanyListItem
                        to="aboutus"
                        onClick={() => navigate("/company#aboutus")}
                    >
                        About Us
                    </CompanyListItem>
                    <CompanyListItem
                        to="roadmap"
                        onClick={() => navigate("/roadmap")}
                    >
                        Roadmap
                    </CompanyListItem>
                </CompanyDropDownList>
            </CompanyDropDownListContainer>
        </CompanyDropDownContainer>
    );
}

export default CompanyDropdown;
