import React, {useEffect, useState} from "react";

import {HeroContainer, HeroContent, HeroH1,} from "./HeroElements";
import {ParallaxImage} from "./HeroElements";

function HeroSection() {
    const [backgroundPosition, setBackgroundPosition] = useState("50%");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setBackgroundPosition(`${50 - (window.scrollY / 5)}%`);
        });
    }, []);


    return (
        <HeroContainer id='company'>
            <ParallaxImage image="ourcompanycapy.png"
                           style={{backgroundPositionY: backgroundPosition}}
            />
            <HeroContent>
                <HeroH1>OUR COMPANY - CAPY AI</HeroH1>
            </HeroContent>
        </HeroContainer>


    );
}

export default HeroSection;
