import styled from 'styled-components';
import React from "react";

export const Table = styled.table`
  flex: 1;
  border-top: 5px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: #f5f9fc;
  display: inline-block;
  color: black;
  padding: 3.5rem;
  box-shadow: 10px 10px 10px #888;
  border-radius:25px;
  margin: 20px;
  justify-content: center;
  align-items: center;
`;

export const TableHead = styled.thead`
  border: 5px solid #999;
  padding: 0.5rem;
  text-align: left;


`;

export const TFoot = styled.tfoot`
  // custom css goes here
`;

export const TableBody = styled.tbody`
  border: 5px solid #999;
  text-align: left;
`;

export const TableHeaderTR = styled.tr`
  background-color: #ffffff;
`;

export const TableHeaderTH = styled.th`
  color: #000000;
  padding: 2.5rem;
`;

export const TableTR = styled.tr`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
`;

export const TableTH = styled.th`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
  padding: 2.5rem;
`;

export const TD = styled.td`
    border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
`;