let dataElements = [
    {
        id: 1,
        title: "Q1 2023",
        // location: "Dragontail, Ascana",
        description1: "Launch website",
        description2: "Create Whitepaper",
        description3:  "Create and Deploy smallBlox ERC20 token on the mainnet",
        // buttonText: "View Frontend Projects",
        // date: "Q1 2022",
        icon: "complete",
    },
    {
        id: 2,
        title: "Q2 2023",
        // location: "Skystead, Craonia",
        description1:"Deploy token a launchpad",
        description2:  "Create and Deploy smallBlox ERC20 token on the mainnet",
        description3:  "Deploy beta version of the smallBlox app",
        // buttonText: "View Backend Projects",
        // date: "Q2 2022",
        icon: "incomplete",
    },
    {
        id: 3,
        title: "Q3 2023",
        // location: "South Warren, Geshington",
        description:
            "Assessing the quality of specifications and technical design documents in order to ensure timely, relevant and meaningful feedback.",
        // buttonText: "Company Website",
        // date: "Q3 2022",
        icon: "incomplete",
    },
    {
        id: 4,
        title: "Q4 2023",
        // location: "South Warren, Geshington",
        description:
            "Online Course in Magical Beasts and Wonders of the World - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque sagittis tellus, non ultrices lacus tempus vel.",
        // buttonText: "Course Certificate",
        // date: "Q4 2022",
        icon: "incomplete",
    },
    {
        id: 5,
        title: "Q1 2024",
        // location: "Skystead, Craonia",
        description:
            "College - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque sagittis tellus, non ultrices lacus tempus vel.",
        // buttonText: "College Projects",
        // date: "Q1 2023",
        icon: "incomplete",
    },
    {
        id: 6,
        title: "Q2 2024",
        // location: "Dragontail, Ascana",
        description:
            "Highschool - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque sagittis tellus, non ultrices lacus tempus vel.",
        // date: "Q2 2023",
        icon: "incomplete",
    },
];

export default dataElements;
