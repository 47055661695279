import React, {useState} from "react";
import SideBar from "../../components/SideBar";
import NavBar from "../../components/NavBar";
import ScrollToTop from "../../components/ScrollToTop";
import Footer from "../../components/Footer";
import Whitepaper from "../../components/Whitepaper";

function WhitepaperPage() {
    const [isOpen, SetIsOpen] = useState(false);

    const toggle = () => {
        SetIsOpen(!isOpen);
    };
    return (
        <>
            <SideBar isOpen={isOpen} toggle={toggle}/>
            <NavBar toggle={toggle}/>
            <Whitepaper/>
            <ScrollToTop/>
            <Footer/>
        </>
    );
}

export default WhitepaperPage;
