import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';

import {ContactContainer, ContactContent, ContactH1, ContactH2, ContactP,} from "./ContactElements";

function ContactSection() {
    const [hover, setHover] = useState(false);

    const navigate = useNavigate();

    const onHover = () => {
        setHover(!hover);
    };

    return (

        <ContactContainer id='contact'>
            <ContactContent>
                <ContactH1>CONTACT US</ContactH1>
                <ContactP>Send us your feedback! We appreciate all your input.</ContactP>
                <ContactH2>Social Media</ContactH2>
            </ContactContent>
        </ContactContainer>

    );
}

export default ContactSection;
