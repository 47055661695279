import React, {PureComponent} from 'react';
import {Cell, Pie, PieChart, Tooltip} from 'recharts';
import {
    TableContainer,
    PieChartContainer,
    TokenomicsContainer,
    TokenomicsGraphContainer,
    TokenomicsH1,
    TokenomicsTableContainer,
} from "./TokenomicsElements";
import {Table, TableBody, TableHeaderTH, TableHeaderTR, TableTH, TableTR} from "../../TableElements";


const data = [
    {name: 'Public', value: 40},
    {name: 'Private', value: 30},
    {name: 'Team', value: 20},
    {name: 'Reserve', value: 10},
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const columns = [
    {
        title: 'Bucket',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Allocation',
        dataIndex: 'value',
        key: 'value',
    },
];

const dataSource = data.map((item) => ({key: item.name, name: item.name, value: `${item.value}%`}));

function TokenomicsSection() {
    return (
        <TokenomicsContainer id='tokenomics'>>
            <TokenomicsH1>CAPYAI TOKENOMICS</TokenomicsH1>
            <TokenomicsTableContainer>
                <Table>
                    <TableBody>
                        <TableTR>
                            <TableTH>TOKEN NAME</TableTH>
                            <TableTH>CAPYAI</TableTH>
                        </TableTR>
                        <TableTR>
                            <TableTH>TOKEN TYPE</TableTH>
                            <TableTH>ERC20</TableTH>
                        </TableTR>
                        <TableTR>
                            <TableTH>NETWORK</TableTH>
                            <TableTH>Polygon</TableTH>
                        </TableTR>
                        <TableTR>
                            <TableTH>BLOCKCHAIN</TableTH>
                            <TableTH>Ethereum</TableTH>
                        </TableTR>
                    </TableBody>
                </Table>
                <Table>
                    <TableBody>
                        <TableTR>
                            <TableTH>CONSENSUS ALGORITHM</TableTH>
                            <TableTH>Proof-of-Stake</TableTH>
                        </TableTR>
                        <TableTR>
                            <TableTH>DECIMALS</TableTH>
                            <TableTH>8</TableTH>
                        </TableTR>
                        <TableTR>
                            <TableTH>INITIAL CIRCULATING SUPPLY</TableTH>
                            <TableTH>50 million</TableTH>
                        </TableTR>
                        <TableTR>
                            <TableTH>TOTAL SUPPLY</TableTH>
                            <TableTH>30 billion</TableTH>
                        </TableTR>
                    </TableBody>
                </Table>
            </TokenomicsTableContainer>

            <TokenomicsH1>TOKEN ALLOCATION</TokenomicsH1>
            <TokenomicsGraphContainer>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableHeaderTR>
                                <TableHeaderTH>Bucket</TableHeaderTH>
                                <TableHeaderTH>Allocation</TableHeaderTH>
                                <TableHeaderTH>Vesting Period</TableHeaderTH>
                                <TableHeaderTH>Cliff</TableHeaderTH>
                            </TableHeaderTR>
                            <TableTR>
                                <TableTH>Public</TableTH>
                                <TableTH>10%</TableTH>
                                <TableTH>12 months</TableTH>
                                <TableTH>12 months</TableTH>
                            </TableTR>
                            <TableTR>
                                <TableTH>Private</TableTH>
                                <TableTH>8%</TableTH>
                                <TableTH>12 months</TableTH>
                                <TableTH>12 months</TableTH>
                            </TableTR>
                            <TableTR>
                                <TableTH>Seed</TableTH>
                                <TableTH>8%</TableTH>
                                <TableTH>12 months</TableTH>
                                <TableTH>12 months</TableTH>
                            </TableTR>
                            <TableTR>
                                <TableTH>Operations</TableTH>
                                <TableTH>28%</TableTH>
                                <TableTH>12 months</TableTH>
                                <TableTH>12 months</TableTH>
                            </TableTR>
                        </TableBody>
                    </Table>
                </TableContainer>
                <PieChartContainer>
                    <PieChart width={800} height={400} onMouseEnter={PureComponent.onPieEnter}>
                        <text color={'#fffff'} x={200} y={200} textAnchor="middle" dominantBaseline="middle">
                            CAPYAI
                        </text>
                        <Pie
                            data={data}
                            cx={220}
                            cy={200}
                            text={"test"}
                            innerRadius={130}
                            outerRadius={180}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                            ))}
                        </Pie>
                        <Tooltip formatter={(value, name) => [`${name}: ${value}%`]}/>
                    </PieChart>
                </PieChartContainer>

            </TokenomicsGraphContainer>
        </TokenomicsContainer>
    )
        ;
}

export default TokenomicsSection;
