import React, {useState} from "react";
import SideBar from "../../components/SideBar";
import NavBar from "../../components/NavBar";
import ScrollToTop from "../../components/ScrollToTop";
import Footer from "../../components/Footer";
import AboutSection from "../../components/Company/AboutSection";
import HeroSection from "../../components/Company/HeroSection";
import VisionSection from "../../components/Company/VisionSection";
import {PageContainer} from "../PageElements";

function CompanyPage() {
    const [isOpen, SetIsOpen] = useState(false);

    const toggle = () => {
        SetIsOpen(!isOpen);
    };

    return (
        <PageContainer>
            <SideBar isOpen={isOpen} toggle={toggle}/>
            <NavBar toggle={toggle}/>
            <HeroSection/>
            <VisionSection/>
            <AboutSection/>
            <AboutSection/>
            <VisionSection/>
            {/*<ScrollToTop/>*/}
            <Footer/>
        </PageContainer>
    );
}

export default CompanyPage;
