import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {tokenSectionData} from "./Data";
import {
    Heading,
    Input,
    WaitlistContainer,
    WaitlistForm,
    WaitlistFormContainer,
    WaitlistWrapper,
} from "./WaitlistSectionElements";
import {Button} from "../ButtonElements";
import {ArrowForward, ArrowRight} from "../Home/HeroSection/HeroElements";

function WaitlistSection() {
    const [hover, setHover] = useState(false);
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    const onHover = () => {
        setHover(!hover);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add code here to send the email address to your back-end for storage
    };

    return (
        <WaitlistContainer lightBackground={tokenSectionData.lightBackground} id={tokenSectionData.id}>
            <WaitlistWrapper>
                <Heading>Join the waitlist:</Heading>
                <WaitlistFormContainer>
                        <WaitlistForm onSubmit={handleSubmit}>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                required
                            />
                            <Button
                                to='submit'
                                onMouseEnter={onHover}
                                onClick={() => navigate('/waitlist')}
                                primary='true'
                                dark='true'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                            >
                                Subscribe {hover ? <ArrowForward/> : <ArrowRight/>}
                            </Button>

                        </WaitlistForm>
                    </WaitlistFormContainer>
            </WaitlistWrapper>
        </WaitlistContainer>

    );
}

export default WaitlistSection;
