import styled from "styled-components";
import {MdKeyboardArrowRight, MdArrowForward} from "react-icons/md";

export const HeroContainer = styled.div`
  min-height: 500px;
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;


export const ParallaxImage = styled.div`
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-attachment: fixed;
  background-image: url(${props => props.image});
  background-size: cover;
  width: 100%;
  height: 260%;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 900px;
  flex-direction: column;
  align-items: top;
  margin-bottom: 350px
`;

export const HeroH1 = styled.h1`
  padding: 380px 50px 50px 50px;
  color: #000000;
  font-size: 54px;
  text-align: left;
  margin-left: -500px;
  max-width: 800px;
  text-shadow: -2px -2px 0 #00b8ff, 2px -2px 0 #00b8ff, -2px 2px 0 #00b8ff, 2px 2px 0 #00b8ff;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroButtonWrapper = styled.div`
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ImageWrapper = styled.div`
  // max-width: 555px;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  // margin: 0 0 10px 0;
  padding-right: 0;
`;



export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled.input`
  padding: 20px;
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;