import React from "react";
import "./App.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import CompanyPage from "./pages/Company/company";
import RoadmapPage from "./pages/Company/roadmap";
import WhitepaperPage from "./pages/Token/whitepaper";
import Home from "./pages";
import WaitlistPage from "./pages/waitlist";
import TokenPage from "./pages/Token/token";
import ContactPage from "./pages/contact";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path='/waitlist' element={< WaitlistPage />}/>
                <Route path='/token' element={< TokenPage />} exact/>
                <Route path='/company' element={< CompanyPage />} exact/>
                <Route path='/whitepaper' element={< WhitepaperPage />} exact/>
                <Route path='/roadmap' element={< RoadmapPage />} exact/>
                <Route path='/contact' element={< ContactPage  />}/>
            </Routes>
        </Router>
    );
}

export default App;
